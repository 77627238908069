import React, {useEffect, useRef, useState} from 'react';
import Loader from "../../Chat/components/utils/Loader";
import Play from "./Play";
import {
    americanToDec,
    bookToFullImgSrc,
    floored_cents_to_dollars,
    prettyMktName
} from "../../Chat/components/utils/utils";
import externalLinkIcon from "../../../images/externalLink.svg";
import {DateTime} from "luxon";
import rightArrow from "../../../images/rightarrow.svg";
import downArrow from "../../../images/downarrow.svg";
import emptyIcon from "../../../images/empty.png";
import cross from "../../../images/cross.svg";
import cashImg from "../../../images/pcash.svg";
import pointsImg from "../../../images/ppoints.svg";
import nvgCashImg from "../../../images/novigcash.png";
import nvgCoinImg from "../../../images/novigcoin.png";
import parlayBlurImg from "../../../images/parlayblur.png";
import Scratchpad from './Scratchpad';


function Leg({leg, removeLeg, copyToScratchpad}) {
    const [isLoading, setIsLoading] = useState(false);
    const hasDeeplink = (('deeplink' in leg) && (leg['deeplink'] !== '') && (leg['deeplink'] !== null));

    useEffect(() => {
        setIsLoading(false);
    }, [leg]);

    const gotoLink = (url) => {
        if (!url) {
            return;
        }
        window.open(url, '_blank');
    }

    const removeHandler = () => {
        setIsLoading(true);
        removeLeg(leg.ockey);
    }

    return (
        <div className={`${leg.is_active ? '' : 'line-through'} py-2 mx-4 flex flex-row justify-between`} style={{height: 96}}>
            {isLoading && <Loader darkness={300}/>}
            {!isLoading &&
                <>
                    <div className="flex flex-row gap-4 items-start">
                        <div className="flex flex-col justify-center items-center cursor-pointer" onClick={() => gotoLink(leg['deeplink'])}>
                            <div className="relative group cursor-pointer">
                                <span className="absolute text-sm pointer-events-none z-101 -top-7 left-0 w-max opacity-0 transition-opacity duration-75 group-hover:opacity-100 bg-black text-white rounded-md px-1">{leg.bookie}</span>
                                <img src={bookToFullImgSrc(leg.bookie_key)} alt={leg.bookie} height={48} width={48} className={`${leg.is_active ? '' : 'grayscale'} rounded-md`} />
                            </div>
                            <div className="flex flex-row gap-1 items-center">
                                <div className="justify-center">
                                    <p className="text-center text-black dark:text-white font-bold">{leg.odds}</p>
                                    <p className="text-center font-medium text-xs text-gray-500 dark:text-dark-text-secondary">{floored_cents_to_dollars(leg.liquidity)}</p>
                                </div>
                                { hasDeeplink ? <img src={externalLinkIcon} alt="external link" height={18} width={18}/> : null}
                            </div>
                        </div>

                        <div className="flex flex-col justify-center">
                            <div>
                                {!!leg.participant && <p className="text-black dark:text-white text-md font-bold">{leg.participant_name}</p> }
                                <p className="text-gray-500 dark:text-dark-text-secondary text-md">{leg.name} {(leg.handicap !== 0) ? leg.handicap : ''} {prettyMktName(leg.market_key)}</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col justify-between items-end">
                        <div className="cursor-pointer w-fit h-fit rounded-md hover:bg-gray-300 dark:hover:bg-dark-bg-primary" onClick={removeHandler}>
                            <img src={cross} alt="cross" />
                        </div>
                        <p onClick={() => copyToScratchpad(leg.bookie_key, leg.ockey)} className="text-sm text-gray-500 dark:text-dark-text-secondary underline cursor-pointer">Edit</p>
                    </div>
                </>
            }
        </div>
    );
}



function GameView({initLegs, isCollapsed, legRemoveHandlerSlip, copyToScratchpad}) {
    const league = initLegs[0].game_info.league
    const game = initLegs[0].game_info.game;
    const [legs, setLegs] = useState(initLegs);
    const [collapsed, setCollapsed] = useState(isCollapsed);
    const toggleExpanded = () => {
        setCollapsed(!collapsed);
    };
    useEffect(() => {
        setLegs(initLegs);
    }, [initLegs]);

    const legRemoveHandler = (ockey) => {
        const body = {ocKey: ockey};
        fetch('/betslip/remove', {
            'method': 'POST',
            'body': JSON.stringify(body),
            'headers': {
                'Content-Type': "application/json",
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            const newLegs = legs.filter(leg => leg.ockey !== ockey);
            setLegs(newLegs);
            legRemoveHandlerSlip(ockey);
        }).catch(err => {
            console.error(err);
            const statusCode = err.status;
            alert(`Error ${statusCode} occurred.`);
        });
    }

    if (legs.length === 0) {
        return <></>;
    }
    return (
        <div className="mb-2">
            <div className="px-2">
                <div className="rounded-lg border border-gray-200 dark:border-dark-border-primary dark:bg-dark-bg-primary-hover">

                    {/*Code for Single Collapsible Start*/}
                    <div onClick={toggleExpanded} className="">
                        <div className="flex flex-row items-center justify-between bg-white dark:bg-dark-bg-primary border border-gray-200 dark:border-dark-border-primary hover:bg-gray-100 dark:hover:bg-dark-bg-secondary cursor-pointer rounded-lg px-4 py-2">
                            <div>
                                <div className="flex flex-row items-center gap-2">
                                    <div className="text-black dark:text-white">
                                        {!!game.away_team_img &&
                                            <div className="relative group cursor-pointer">
                                                <span className="absolute text-sm pointer-events-none z-101 -bottom-4 left-0 w-max opacity-0 transition-opacity duration-75 group-hover:opacity-100 bg-black text-white rounded-md px-1">{game.away_team}</span>
                                                <img src={game.away_team_img} alt="away team" height={league === 'mlb' ? 32 : 38} width={league === 'mlb' ? 28 : 38} />
                                            </div>
                                        }
                                        {!game.away_team_img && league.includes('ncaa') && <p>{game.away_team}</p>}
                                        {!game.away_team_img && !league.includes('ncaa') && <p>{game.away_team.split(' ').at(-1)}</p>}
                                    </div>
                                    <div className="text-black dark:text-white">@</div>
                                    <div className="text-black dark:text-white">
                                        {!!game.home_team_img &&
                                            <div className="relative group cursor-pointer">
                                                <span className="absolute text-sm pointer-events-none z-101 -bottom-4 left-0 w-max opacity-0 transition-opacity duration-75 group-hover:opacity-100 bg-black text-white rounded-md px-1">{game.home_team}</span>
                                                <img src={game.home_team_img} alt="home team" height={league === 'mlb' ? 32 : 38} width={league === 'mlb' ? 28 : 38} />
                                            </div>
                                        }
                                        {!game.home_team_img && league.includes('ncaa') && <p>{game.home_team}</p>}
                                        {!game.home_team_img && !league.includes('ncaa') && <p>{game.home_team.split(' ').at(-1)}</p>}
                                    </div>
                                </div>
                                <p className="text-zinc-500 dark:text-dark-text-secondary text-sm">{ DateTime.fromISO(game.start_timestamp).toRelative() }</p>
                            </div>
                            <div className="flex flex-row gap-1 items-center">
                                <p className="text-zinc-500 dark:text-dark-text-secondary text-sm">({legs.length})</p>
                                <div style={{width: 32, height: 32}}>
                                    {collapsed && <img src={rightArrow} alt="arrow" />}
                                    {!collapsed && <img src={downArrow} alt="arrow" />}
                                </div>
                            </div>
                        </div>
                    </div>

                    {!collapsed &&
                        <div>
                            { legs.map((leg, i) => <Leg key={i} leg={leg} removeLeg={legRemoveHandler} copyToScratchpad={copyToScratchpad}/>) }
                        </div>
                    }

                </div>
            </div>
        </div>
    );
}

const ParlaySheet = ({parlayBooks, numLegs, game}) => {
    const [collapsed, setCollapsed] = useState(true);
    const toggleExpanded = () => {
        setCollapsed(!collapsed);
    };

    const goTo = (url) => {
        if (!url) {
            return;
        }
        window.open(url, '_blank')
    }

    if (!game) {
        return (
            <div className="flex flex-col gap-2 mt-2 px-2">
                {parlayBooks.map((oc, index) => {
                    return (
                        <div onClick={() => goTo(oc.deep)} key={index} className={`dark:bg-dark-bg-primary ${index === 0 ? 'border-green-500' : 'dark:border-dark-border-primary'} ${!!oc.deep ? 'cursor-pointer hover:bg-green-300/80 dark:hover:bg-green-300/10' : ''} bg-white flex flex-row justify-between gap-2 items-center border rounded-lg p-2`}>
                            <div className="flex flex-row gap-2 items-center">
                                <img src={bookToFullImgSrc(oc.bookie_key)} alt={oc.book_key} height={42} width={42} className={`rounded-md`} />
                                <p className="text-gray-600 dark:text-dark-text-secondary text-sm font-bold">{oc.odds > 0 ? '+' : ''}{oc.odds}</p>
                            </div>

                            {!!oc.deep && <img src={externalLinkIcon} alt="external link" height={20} width={20}/>}
                        </div>
                    );
                })}
            </div>
        );
    }

    return (
        <div className="mb-2">
            <div className="px-2">
                <div className="bg-gray-200 rounded-lg border dark:bg-dark-bg-primary-hover dark:border-dark-border-primary">

                    {/*Code for Single Collapsible Start*/}
                    <div onClick={toggleExpanded} className="">
                        <div className="flex flex-row items-center justify-between bg-white dark:bg-dark-bg-primary border border-gray-200 dark:border-dark-border-primary hover:bg-gray-100 dark:hover:bg-dark-bg-secondary cursor-pointer rounded-lg px-4 py-2">
                            <div>
                                <div className="flex flex-row items-center gap-2">
                                    <div>
                                        {!!game.away_team_img &&
                                            <div className="relative group cursor-pointer">
                                                <span className="absolute text-sm pointer-events-none z-101 -bottom-4 left-0 w-max opacity-0 transition-opacity duration-75 group-hover:opacity-100 bg-black text-white rounded-md px-1">{game.away_team}</span>
                                                <img src={game.away_team_img} alt="away team" height={game.league === 'mlb' ? 32 : 38} width={game.league === 'mlb' ? 28 : 38} />
                                            </div>
                                        }
                                        {!game.away_team_img && <p>{game.away_team.split(' ').at(-1)}</p>}
                                    </div>
                                    <div>@</div>
                                    <div>
                                        {!!game.home_team_img &&
                                            <div className="relative group cursor-pointer">
                                                <span className="absolute text-sm pointer-events-none z-101 -bottom-4 left-0 w-max opacity-0 transition-opacity duration-75 group-hover:opacity-100 bg-black text-white rounded-md px-1">{game.home_team}</span>
                                                <img src={game.home_team_img} alt="home team" height={game.league === 'mlb' ? 32 : 38} width={game.league === 'mlb' ? 28 : 38} />
                                            </div>
                                        }
                                        {!game.home_team_img && <p>{game.home_team.split(' ').at(-1)}</p>}
                                    </div>
                                </div>
                                <p className="text-zinc-500 text-sm">{ DateTime.fromISO(game.start_timestamp).toRelative() }</p>
                            </div>
                            <div className="flex flex-row gap-1 items-center">
                                <p className="text-zinc-500 text-sm">({numLegs})</p>
                                <div style={{width: 32, height: 32}}>
                                    {collapsed && <img src={rightArrow} alt="arrow" />}
                                    {!collapsed && <img src={downArrow} alt="arrow" />}
                                </div>
                            </div>
                        </div>
                    </div>

                    {!collapsed &&
                        <div className="flex flex-col gap-2 mt-2 px-2">
                            {parlayBooks.map((oc, index) => {
                                return (
                                    <div onClick={() => goTo(oc.deep)} key={index} className={`dark:bg-dark-bg-primary ${index === 0 ? 'border-green-500' : 'dark:border-dark-border-primary'} ${!!oc.deep ? 'cursor-pointer hover:bg-green-300/80 dark:hover:bg-green-300/10' : ''} bg-gray-50 flex flex-row justify-between gap-2 items-center border rounded-lg p-2`}>
                                        <div className="flex flex-row gap-2 items-center">
                                            <img src={bookToFullImgSrc(oc.bookie_key)} alt={oc.book_key} height={42} width={42} className={`rounded-md`} />
                                            <p className="text-gray-600 text-sm font-bold">{oc.odds > 0 ? '+' : ''}{oc.odds}</p>
                                        </div>

                                        {!!oc.deep && <img src={externalLinkIcon} alt="external link" height={20} width={20}/>}
                                    </div>
                                );
                            })}
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

const BetSlip = (props) => {
    const [isDesktop, setIsDesktop] = useState(true);

    const [stake, setStake] = useState('');
    const [toWin, setToWin] = useState('');

    const [tab, setTab] = useState('betslip');
    const [ isRealMoney, setIsRealMoney ] = useState(true);

    const [hasProphetBeta, setHasProphetBeta] = useState(true);
    const [hasNovigBeta, setHasNovigBeta] = useState(false);
    const [hasBetopenlyBeta, setHasBetopenlyBeta] = useState(true);

    const [errorText, setErrorText] = useState('');
    const [errorUrl, setErrorUrl] = useState('');
    const [successText, setSuccessText] = useState('');
    const [showPaywall, setShowPaywall] = useState(false);

    const [parlayComputeState, setParlayComputeState] = useState('idle'); // idle, computing, computed
    const [isLoadingParlay, setIsLoadingParlay] = useState(true);
    const [parlayBooks, setParlayBooks] = useState({});

    const [ isHidden, setIsHidden ] = useState(true);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ loadingText, setLoadingText ] = useState('');
    const [isLoadingWager, setIsLoadingWager] = useState(false);
    const [ games, setGames ] = useState([]);

    const [ scratchpadTargetBookie, setScratchpadTargetBookie ] = useState('');
    const [ scratchpadOcky, setScratchpadOcky ] = useState('');

    const [displayPlay, setDisplayPlay] = useState(null);

    const copyToScratchpad = (bookie, ockey) => {
        setScratchpadTargetBookie(bookie);
        setScratchpadOcky(ockey);
        setTab('scratchpad');
    }

    const clearSlip = () => {
        setGames([]);
        setParlayBooks({});
        const body = {};
        fetch('/betslip/clear', {
            'method': 'POST',
            'body': JSON.stringify(body),
            'headers': {
                'Content-Type': "application/json",
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
        }).catch(err => {
            console.error(err);
            const statusCode = err.status;
            alert(`Error ${statusCode} occurred.`);
        });
    }

    const closeSlip = () => {
        setIsHidden(true);
    }
    const openParlayTab = () => {
        if (isLoading) {
            return;
        }
        if (Object.values(games).length === 0) {
            return;
        }
        setErrorText('');
        setSuccessText('');
        setTab('parlay');
        if (parlayComputeState !== 'idle') {
            return;
        }
        computeParlay();
    }

    const legRemoveHandlerSlip = (ockey) => {
        setParlayBooks({});
        const curGames = games;
        const newGames = {};
        for (const [gid, legs] of Object.entries(curGames)) {
            const newLegs = legs.filter(leg => leg.ockey !== ockey);
            if (newLegs.length > 0) {
                newGames[gid] = newLegs;
            }
        }
        setGames(newGames);
        setParlayComputeState('idle');
        setIsLoadingParlay(true);
    }

    const computeParlay = () => {
        if (parlayComputeState === 'computing') {
            return;
        }
        setIsLoadingParlay(true);
        setParlayComputeState('computing');
        const legs = Object.values(games).reduce((acc, legs) => acc.concat(legs), []);
        if (legs.length <= 1) {
            setIsLoadingParlay(false);
            return;
        }

        const ockeys = legs.map(leg => leg.ockey);
        const body = {ocKeys: ockeys};
        const url = '/compute/parlay';
        fetch(url, {
            'method': 'POST',
            'body': JSON.stringify(body),
            'headers': {
                'Content-Type': "application/json",
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            const books = data.sort((a, b) => {
                if (a.odds < b.odds) {
                    return 1;
                }
                if (a.odds > b.odds) {
                    return -1;
                }
                return 0;
            });
            // split books by 'game_id'
            const objs = {};
            books.forEach((book) => {
                if (!(book.game_id in objs)) {
                    objs[book.game_id] = [];
                }
                objs[book.game_id].push(book);
            });
            setParlayBooks(objs);
            setParlayComputeState('computed');
            setIsLoadingParlay(false);
        }).catch(err => {
            console.error(err);
            const statusCode = err.status;
            if (+statusCode === 403) {
                setParlayComputeState('computed');
                setIsLoadingParlay(false);
                setShowPaywall(true);
            } else {
                alert(`Error ${statusCode} occurred.`);
            }
        });
    }

    const changeStake = (e) => {
        const v = e.target.value;
        if (isNaN(v)) {
            return;
        }
        const odds = parseInt(Object.values(games)[0][0].odds);
        const decOdds = americanToDec(odds);
        setStake(v);
        setToWin((v * decOdds).toFixed(2));
    }

    const getBetas = () => {
        fetch('/profile/user/beta/novig_wager', {
            'headers': {
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            setHasNovigBeta(data.valid);
        }).catch(err => {
            console.error(err);
            const statusCode = err.status;
            alert(`Error ${statusCode} occurred.`);
        });
    }

    const openHandler = () => {
        setDisplayPlay(null);
        setErrorText('');
        setSuccessText('');
        setIsLoading(true);
        setGames([]);
        setIsHidden(false);

        if (!props.is_auth) {
            setIsLoading(false);
            return;
        }

        getBetas();

        // get the actual data.
        fetch('/betslip/get', {
            'headers': {
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.text();
        }).then(data => {
            const legs = JSON.parse(data);
            let games = {};
            const legsByOckey = {};
            legs.forEach((leg) => {
                const gid = leg.game_info.game.game_id;
                if (!(gid in games)) {
                    games[gid] = [];
                }
                games[gid].push(leg);
                legsByOckey[leg.ockey] = leg;
            });
            setGames(games);
            setIsLoading(false);

            const recentSlipAdds = document.querySelector('div[name="recentSlipAdds"]');
            if (!!recentSlipAdds) {
                Array.from(recentSlipAdds.children).forEach(child => {
                    const ockey = child.dataset.ockey;
                    const odds = parseInt(child.dataset.odds);
                    if (ockey in legsByOckey) {
                        const ockeyOdds = parseInt(legsByOckey[ockey].odds);
                        if (odds !== ockeyOdds) {
                            setErrorText('Odds have changed.');
                        }
                    }
                });
            }
            
            if (!!props.stake && Object.values(games).length > 0) {
                const odds = parseInt(Object.values(games)[0][0].odds);
                const decOdds = americanToDec(odds);
                const v = props.stake;
                setStake(v);
                setToWin((v * decOdds).toFixed(2));

                if (!!props.odds) {
                    if (odds !== props.odds) {
                        setStake('');
                        setToWin('');
                        setErrorText('Odds have changed.');
                    }
                }
            }
        }).catch(err => {
            console.error(err);
            const statusCode = err.status;
            alert(`Error ${statusCode} occurred.`);
        });
    }

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 768); // 768px is standard tablet/mobile breakpoint
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        window.addEventListener("react:open", openHandler);
        if (props.open) {
            openHandler();
        }
        return () => {
            window.removeEventListener("react:open", openHandler);
        };
    }, []);
    useEffect(() => {
        setIsLoadingParlay(true);
        setParlayComputeState('idle');
        setParlayBooks({});
    }, [games]);

    const geoCheck = (book) => {
        if (!isRealMoney) {
            return;
        }
        if (props.email === 'matthew@prop-odds.com' || props.email === 'jmurph0912@gmail.com') {
            placeBet('test');
            return;
        }
        setErrorText('');
        setSuccessText('');
        setIsLoading(true);
        setIsLoadingWager(true);
        setLoadingText('Checking your location...');

        let url = '';
        if (book === 'novig') {
            url = '/wager/novig/geo';
        } else if (book === 'prophetx') {
            url = '/wager/prophetx/geo';
        }
        fetch(url, {
            'method': 'POST',
            'body': JSON.stringify({}),
            'headers': {
                'Content-Type': "application/json",
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            if (!data.success) {
                setErrorText(data.details);
                if (!!data.url) {
                    setErrorUrl(data.url);
                }
                setIsLoading(false);
                setIsLoadingWager(false);
            } else {
                const lic = data.license;
                const uid = data.uid;
                const geoClient = GcHtml5.createClient();
                geoClient.setUserId(uid);
                geoClient.setReason('Play on BeeBettor');
                geoClient.setLicense(lic);

                geoClient.events.on('hint', function(reason, data) {
                    //e.g. show hint messages to user
                    setErrorText(reason);
                }).on('engine.success', function(text, xml) {
                    placeBet(text);
                }).on('*.failed', function(code, message) {
                    setErrorText('');
                    setSuccessText('');
                    setIsLoading(false);
                    setIsLoadingWager(false);
                    setLoadingText('');

                    if (this.event === 'revise.failed' || this.event === 'config.failed' || this.event === 'engine.failed') {
                        if (code === geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION || code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_INVALID_FORMAT || code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED) {
                            setErrorText('Geo location service error. Try again.');
                        } else if (code === geoClient.ErrorCodes.CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS) {
                            console.log('wtf???');
                            //it's safe to be ignored, or we can wait for previous request to complete and try again
                        } else {
                            setErrorText('GeoComply Service Error. Please try again later.')
                        }
                    } else if (this.event === 'browser.failed') {
                        if (code === geoClient.ErrorCodes.CLNT_ERROR_BROWSER_GEOLOCATION_DENIED) {
                            setErrorText('Browser geolocation denied. Please enable location and try again.');
                        } else {
                            setErrorText('Browser geolocation failed. Please try again later.');
                        }
                    }
                });
                // Request Geolocation
                geoClient.request();
            }
        }).catch(err => {
            console.error(err);
            const statusCode = err.status;
            alert(`Error ${statusCode} occurred.`);
        });
    }

    const novigPlay = () => {
        if (!isRealMoney) {
            return;
        }

        setSuccessText('');
        setIsLoadingWager(true);
        setLoadingText('Executing play...');
        const leg = Object.values(games)[0][0];
        const ockey = leg.ockey;
        const odds = +leg.odds;
        const stakeVal = +stake;
        const body = {ocKey: ockey, odds: odds, stake: stakeVal};
        fetch('/wager/novig', {
            'method': 'POST',
            'body': JSON.stringify(body),
            'headers': {
                'Content-Type': "application/json",
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            setIsLoading(false);
            setLoadingText('');
            if (!data.success) {
                setErrorText(data.details);
                if (!!data.url) {
                    setErrorUrl(data.url);
                }
            } else {
                setSuccessText('Play successful!');
                clearSlip();
            }
            setIsLoadingWager(false);
        }).catch(err => {
            console.error(err);
            const statusCode = err.status;
            alert(`Error ${statusCode} occurred.`);
        });
    }

    const getMostRecentPlay = () => {
        // get most recent play from history
        // wait 1 second before fetching most recent play
        setTimeout(() => {
            fetch('/wager/orderbook/plays?is_open=true').then(res => {
                if (!res.ok) {
                    return Promise.reject(res);
                }
                return res.json();
            }).then(data => {
                if (!data.success) {
                    return;
                }
                const plays = data.plays;
                if (plays.length === 0) {
                    return;
                }
                const prophetxPlay = plays.find(p => p.bookie === 'prophetx');
                if (!prophetxPlay) {
                    return;
                }
                const play = plays[0];
                setDisplayPlay(play);
            });
        }, 1000);
    }

    const betopenlyRequest = (body) => {
        console.log(body);
        fetch('/wager/betopenly', {
            'method': 'POST',
            'body': JSON.stringify(body),
            'headers': {
                'Content-Type': "application/json",
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            setIsLoading(false);
            setLoadingText('');
            if (!data.success) {
                setErrorText(data.details);
                if (!!data.url) {
                    setErrorUrl(data.url);
                }
            } else {
                setSuccessText('Play successful!');
                clearSlip();
                // getMostRecentPlay();
            }
            setIsLoadingWager(false);
        }).catch(err => {
            console.error(err);
            const statusCode = err.status;
            alert(`Error ${statusCode} occurred.`);
        });
    }

    const placeBetopenlyBet = () => {
        setSuccessText('');
        setIsLoadingWager(true);
        setLoadingText('Executing play...');
        const leg = Object.values(games)[0][0];
        const ockey = leg.ockey;
        const odds = +leg.odds;
        const stakeVal = +stake;


        setErrorText('');
        setSuccessText('');
        setIsLoading(true);
        setIsLoadingWager(true);
        setLoadingText('Checking your location...');
        navigator.geolocation.getCurrentPosition(position => {
            fetch('https://api.ipify.org?format=json', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
            })
            .then(res => res.json())
            .then(data => {
                console.log(position);
                console.log(data.ip);
                const lat = position.coords.latitude;
                const long = position.coords.longitude;
                const body = {
                    ocKey: ockey,
                    odds: odds,
                    stake: stakeVal,
                    is_desktop: isDesktop,
                    is_share: !!props.is_share,
                    lat: lat,
                    long: long,
                    ip: data.ip,
                };
                setLoadingText('Placing bet...');
                betopenlyRequest(body);
            })
            .catch(err => {
                console.error('Error fetching IP:', err);
            });
        });

    }
    
    const placeBet = (encrpytedText) => {
        if (!isRealMoney) {
            return;
        }
        if (!encrpytedText) {
            return;
        }
        setSuccessText('');
        setIsLoadingWager(true);
        setLoadingText('Executing play...');
        const leg = Object.values(games)[0][0];
        const ockey = leg.ockey;
        const odds = +leg.odds;
        const stakeVal = +stake;
        const body = {
            ocKey: ockey,
            odds: odds,
            stake: stakeVal,
            encrypted: encrpytedText,
            is_desktop: isDesktop,
            is_share: !!props.is_share,
        };
        fetch('/wager/prophetx', {
            'method': 'POST',
            'body': JSON.stringify(body),
            'headers': {
                'Content-Type': "application/json",
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            setIsLoading(false);
            setLoadingText('');
            if (!data.success) {
                setErrorText(data.details);
                if (!!data.url) {
                    setErrorUrl(data.url);
                }
            } else {
                setSuccessText('Play successful!');
                clearSlip();
                // getMostRecentPlay();
            }
            setIsLoadingWager(false);
        }).catch(err => {
            console.error(err);
            const statusCode = err.status;
            alert(`Error ${statusCode} occurred.`);
        });
    }

    if (isHidden) {
        return <></>;
    }
    return (
        <div className="z-100 fixed bottom-2 md:right-2 p-2 md:p-3 md:w-1/2 lg:w-2/5 w-full h-[calc(100%-3rem)] md:h-5/6">
            <div className="relative border border-gray-200 dark:border-dark-border-primary bg-gray-50 dark:bg-dark-bg-secondary flex flex-col h-full mt-2 mx-4 rounded-lg pt-2">
                <div className="border-b border-gray-200 dark:border-dark-border-primary justify-between flex flex-row px-2">
                    <div className="flex flex-row gap-1 select-none font-semibold">
                        <div onClick={() => setTab('betslip')} className={`${tab === 'betslip' ? 'border-b-4' : 'border-b-0'} text-neutral-600 dark:text-dark-text-secondary py-2 px-4 border-yellow-300 hover:bg-gray-100 dark:hover:bg-dark-bg-primary cursor-pointer rounded-t-lg`}>Plays</div>
                        <div onClick={() => setTab('scratchpad')} className={`${tab === 'scratchpad' ? 'border-b-4' : 'border-b-0'} text-neutral-600 dark:text-dark-text-secondary py-2 px-4 border-yellow-300 hover:bg-gray-100 dark:hover:bg-dark-bg-primary cursor-pointer rounded-t-lg`}>Scratchpad</div>
                        <div onClick={openParlayTab} className={`${tab === 'parlay' ? 'border-b-4' : 'border-b-0'} ${(isLoading || (Object.values(games).length === 0)) ? 'text-neutral-300 dark:text-dark-text-secondary' : 'text-neutral-600 dark:text-dark-text-secondary hover:bg-gray-100 dark:hover:bg-dark-bg-primary cursor-pointer'} py-2 px-4 border-yellow-300 rounded-t-lg`}>SGP</div>
                    </div>

                    <div className="w-fit h-fit rounded-md hover:bg-gray-200 dark:hover:bg-dark-bg-primary-hover" onClick={closeSlip}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                             strokeLinejoin="round"
                             className="feather feather-x cursor-pointer rounded-full">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </div>
                </div>

                {isLoading &&
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        <div data-join-target="loader" role="status" className="items-center">
                            <svg aria-hidden="true" className={`w-12 h-12 mx-auto text-transparent animate-spin fill-yellow-300`}
                                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"/>
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                        <p className="gradient-text bg-gradient-to-r from-gray-500 to-gray-100 font-semibold">{loadingText}</p>
                    </div>
                }

                {/*Betslip Tab*/}
                {!isLoading && (tab === 'betslip') &&
                    <>
                        <div className="mt-2 px-2 flex flex-row justify-center">
                            {!!errorUrl && <a href={errorUrl} className="w-full text-center underline text-blue-400 hover:text-blue-600 font-semibold">{errorText}</a>}
                            {!errorUrl && <p className="text-center text-red-500 font-semibold">{errorText}</p>}
                            <p className="text-center text-green-500 font-semibold">{successText}</p>
                        </div>
                        {
                            !!displayPlay && <div className="mt-2 px-4 flex flex-row justify-center"><Play play={displayPlay} /></div>
                        }
                        {
                            Object.values(games).length > 0 &&
                            <div onClick={clearSlip} className="mt-2 px-4 flex flex-row justify-end">
                                <p className="w-fit text-right underline text-gray-600 dark:text-dark-text-secondary font-medium cursor-pointer">Clear</p>
                            </div>
                        }
                        <div className="overflow-y-auto h-full px-2 mt-2">
                            {!props.is_auth &&
                                <div className="border-2 border-dashed h-full flex flex-col items-center justify-center">
                                    <a href="/users/sign_in" className="text-blue-600 hover:text-blue-700 text-lg font-semibold underline">Sign in to make some plays!</a>
                                </div>
                            }
                            {props.is_auth && Object.values(games).length === 0 && !displayPlay &&
                                <div className="border-2 border-gray-200 dark:border-dark-border-primary h-full flex flex-col items-center justify-center">
                                    <img src={emptyIcon} alt="external link" height={96} width={96}/>
                                    <p>Add some plays to get started!</p>
                                </div>
                            }
                            {Object.values(games).map((legs, index) => <GameView key={index} isCollapsed={false} initLegs={legs} legRemoveHandlerSlip={legRemoveHandlerSlip} copyToScratchpad={copyToScratchpad} />)}
                        </div>
                    </>
                }

                {/*Scratchpad Tab*/}
                {!isLoading &&
                    <div className={`${tab === 'scratchpad' ? 'block' : 'hidden'}`}>
                        <div className="mt-2 px-2 flex flex-row justify-center">
                            {!!errorUrl && <a href={errorUrl} className="w-full text-center underline text-blue-400 hover:text-blue-600 font-semibold">{errorText}</a>}
                            {!errorUrl && <p className="text-center text-red-500 font-semibold">{errorText}</p>}
                            <p className="text-center text-green-500 font-semibold">{successText}</p>
                        </div>
                        {props.is_auth &&
                            <div className="px-2 mt-2">
                                <Scratchpad targetBookie={scratchpadTargetBookie} ockey={scratchpadOcky} />
                            </div>
                        }
                    </div>
                }

                {/*Parlay Tab*/}
                {!isLoading && (tab === 'parlay') &&
                    <div className="overflow-y-auto h-full mt-2">
                        {isLoadingParlay &&
                            <div className="flex flex-col gap-2 font-bold h-full items-center justify-center">
                                <div role="status" className="w-full flex items-center">
                                    <svg aria-hidden="true" className={`w-12 h-12 mx-auto text-transparent animate-spin fill-yellow-300`}
                                         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"/>
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"/>
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <p className="gradient-text bg-gradient-to-r from-gray-500 to-gray-100">Computing parlay odds...</p>
                            </div>
                        }
                        {!isLoadingParlay &&
                            <>
                                <div onClick={computeParlay} className="mt-2 px-4 flex flex-row justify-end">
                                    <p className="w-fit text-right underline text-gray-600 dark:text-dark-text-secondary font-medium cursor-pointer">Refresh</p>
                                </div>
                                <div className="flex flex-col gap-2 mt-2 px-2">
                                    {Object.values(games).reduce((acc, legs) => acc + legs.length, 0) <= 1 && <p className="w-full text-center text-gray-600 dark:text-dark-text-secondary">Not enough plays selected.</p>}
                                    {Object.values(games).reduce((acc, legs) => acc + legs.length, 0) > 1 && parlayBooks.length === 0 && <p className="w-full text-center text-gray-600 dark:text-dark-text-secondary">No results found.</p>}

                                    {null in parlayBooks && parlayBooks[null].length > 0 && <h2 className="border-b border-gray-200 dark:border-dark-border-primary font-semibold text-gray-500 dark:text-dark-text-secondary">All legs</h2>}
                                    {null in parlayBooks && <ParlaySheet parlayBooks={parlayBooks[null]} numLegs={Object.values(games).reduce((acc, legs) => acc + legs.length, 0)} game={null} />}
                                    {Object.values(parlayBooks).length > 1 && <h2 className="border-b border-gray-200 dark:border-dark-border-primary font-semibold text-gray-500 dark:text-dark-text-secondary">By Game</h2>}
                                    {Object.entries(parlayBooks).map(([gid, books], index) => {
                                        return (!!gid && gid !== 'null' && <ParlaySheet parlayBooks={books}
                                                                                        key={index}
                                                                                        numLegs={!!games[books[0].game_id] ? games[books[0].game_id].length : Object.values(games).reduce((acc, legs) => acc + legs.length, 0) }
                                                                                        game={!!games[books[0].game_id] ? games[books[0].game_id][0].game_info.game : null } />);
                                    })}
                                </div>
                            </>
                        }
                        {!isLoadingParlay && showPaywall &&
                            <>
                                <div className="relative">
                                    <img src={parlayBlurImg} alt="points" className={``} />
                                    <div className="flex flex-col justify-center items-center text-center absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 background-blur-lg bg-white/20 rounded-lg w-full h-full">
                                        <a href="/join">
                                            <div className="items-center flex flex-row gap-4 bg-gradient-to-r from-yellow-300 to-amber-500 rounded-lg text-xl font-black text-black shadow-md hover:bg-yellow-400 cursor-pointer w-fit py-4 px-12 hover:scale-[1.05] duration-300">
                                                <p className="w-full">Unlock SGP</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                }

                {!isLoading && (tab === 'betslip') && hasProphetBeta && Object.values(games).reduce((acc, legs) => acc + legs.length, 0) === 1 && (Object.values(games)[0][0].bookie_key === 'prophetx') &&
                    <div className="relative bottom-0 flex flex-col px-4 gap-2 border-t border-gray-200 dark:border-dark-border-primary items-center">
                        <div className="border border-gray-200 dark:border-dark-border-primary flex flex-row rounded-lg w-fit mx-auto md:mx-0 mt-4">
                            <div onClick={() => setIsRealMoney(false)} className={`${isRealMoney ? 'bg-gray-200 hover:bg-gray-300 dark:bg-dark-bg-secondary' : 'dark:bg-dark-bg-primary'} items-center flex flex-row gap-1 px-4 h-fit cursor-pointer text-center py-2 rounded-l-lg`}>
                                <img src={pointsImg} alt="points" height={32} width={32} className={`${isRealMoney ? 'grayscale' : ''}`} />
                                <p className={`${isRealMoney ? 'text-gray-500 dark:text-dark-text-secondary' : 'text-black dark:text-dark-text-primary'} font-bold`}>Prophet Points</p>
                            </div>
                            <div onClick={() => setIsRealMoney(true)} className={`${isRealMoney ? 'dark:bg-dark-bg-primary' : 'bg-gray-200 hover:bg-gray-300 dark:bg-dark-bg-secondary'} items-center flex flex-row gap-1 px-4 h-fit cursor-pointer text-center py-2 rounded-r-lg`}>
                                <img src={cashImg} alt="cash" height={32} width={32} className={`${isRealMoney ? '' : 'grayscale'}`} />
                                <p className={`${isRealMoney ? 'text-black dark:text-dark-text-primary' : 'text-gray-500 dark:text-dark-text-secondary'} font-bold`}>Prophet Cash</p>
                            </div>
                        </div>

                        {isLoadingWager &&
                            <div className="absolute bg-gray-300/50 w-full h-full">
                                <Loader darkness={400} />
                            </div>
                        }


                        <div className="flex flex-row justify-around gap-2">
                            <div className="border border-gray-200 dark:border-dark-border-primary flex flex-col gap-1 rounded-md bg-white dark:bg-dark-bg-primary pt-1 px-2">
                                <p className="font-bold text-xs dark:text-dark-text-primary">STAKE</p>
                                <div className="flex flex-row items-center">
                                    <p className="font-bold dark:text-dark-text-primary">$</p>
                                    <input type="text" autoCorrect="off" className="py-1 outline-0 ring-0 focus:ring-0 w-full border-none !bg-transparent" value={stake} onChange={changeStake} />
                                </div>
                            </div>
                            <div className="bg-green-200 dark:bg-green-600 border border-green-400 dark:border-dark-border-primary flex flex-col gap-1 rounded-md pt-1 px-2">
                                <p className="font-bold text-xs dark:text-dark-text-primary">TO WIN</p>
                                <div className="flex flex-row items-center">
                                    <p className="font-bold dark:text-dark-text-primary">$</p>
                                    <input type="text" autoCorrect="off" className="py-1 outline-0 ring-0 focus:ring-0 w-full border-none !bg-transparent" value={toWin} onChange={() =>{}} />
                                </div>
                            </div>
                        </div>
                        <div onClick={() => geoCheck('prophetx')} className={`${isRealMoney ? 'cursor-pointer text-black hover:scale-[1.02] bg-gradient-to-r from-yellow-300 to-amber-500' : 'bg-gray-300 text-gray-500'} mb-4 items-center flex flex-row gap-4 rounded-lg text-xl font-bold shadow-md w-full text-center py-2 px-8 duration-100`}>
                            <p className="w-full">PLAY</p>
                        </div>
                    </div>
                }


                {!isLoading && (tab === 'betslip') && hasBetopenlyBeta && Object.values(games).reduce((acc, legs) => acc + legs.length, 0) === 1 && (Object.values(games)[0][0].bookie_key === 'betopenly') &&
                    <div className="relative bottom-0 flex flex-col px-4 pt-4 gap-2 border-t border-gray-200 dark:border-dark-border-primary items-center">

                        {isLoadingWager &&
                            <div className="absolute bg-gray-300/50 w-full h-full">
                                <Loader darkness={400} />
                            </div>
                        }


                        <div className="flex flex-row justify-around gap-2">
                            <div className="border border-gray-200 dark:border-dark-border-primary flex flex-col gap-1 rounded-md bg-white dark:bg-dark-bg-primary pt-1 px-2">
                                <p className="font-bold text-xs dark:text-dark-text-primary">STAKE</p>
                                <div className="flex flex-row items-center">
                                    <p className="font-bold dark:text-dark-text-primary">$</p>
                                    <input type="text" autoCorrect="off" className="py-1 outline-0 ring-0 focus:ring-0 w-full border-none !bg-transparent" value={stake} onChange={changeStake} />
                                </div>
                            </div>
                            <div className="bg-green-200 dark:bg-green-600 border border-green-400 dark:border-dark-border-primary flex flex-col gap-1 rounded-md pt-1 px-2">
                                <p className="font-bold text-xs dark:text-dark-text-primary">TO WIN</p>
                                <div className="flex flex-row items-center">
                                    <p className="font-bold dark:text-dark-text-primary">$</p>
                                    <input type="text" autoCorrect="off" className="py-1 outline-0 ring-0 focus:ring-0 w-full border-none !bg-transparent" value={toWin} onChange={() =>{}} />
                                </div>
                            </div>
                        </div>
                        <div onClick={placeBetopenlyBet} className={`${isRealMoney ? 'cursor-pointer text-black hover:scale-[1.02] bg-gradient-to-r from-yellow-300 to-amber-500' : 'bg-gray-300 text-gray-500'} mb-4 items-center flex flex-row gap-4 rounded-lg text-xl font-bold shadow-md w-full text-center py-2 px-8 duration-100`}>
                            <p className="w-full">PLAY</p>
                        </div>
                    </div>
                }


                {!isLoading && (tab === 'betslip') && hasNovigBeta && Object.values(games).reduce((acc, legs) => acc + legs.length, 0) === 1 && (Object.values(games)[0][0].bookie_key === 'novig') &&
                    <div className="relative bottom-0 flex flex-col px-4 gap-2 border-t border-gray-200 dark:border-dark-border-primary items-center">
                        <div className="border border-gray-200 dark:border-dark-border-primary flex flex-row rounded-lg w-fit mx-auto md:mx-0 mt-4">
                            <div onClick={() => setIsRealMoney(false)} className={`${isRealMoney ? 'bg-gray-200 hover:bg-gray-300 dark:bg-dark-bg-secondary' : 'dark:bg-dark-bg-primary'} items-center flex flex-row gap-1 px-4 h-fit cursor-pointer text-center py-2 rounded-l-lg`}>
                                <img src={nvgCoinImg} alt="points" height={32} width={32} className={`${isRealMoney ? 'grayscale' : ''}`} />
                                <p className={`${isRealMoney ? 'text-gray-500 dark:text-dark-text-secondary' : 'text-black dark:text-dark-text-primary'} font-bold`}>Novig Coins</p>
                            </div>
                            <div onClick={() => setIsRealMoney(true)} className={`${isRealMoney ? 'dark:bg-dark-bg-primary' : 'bg-gray-200 hover:bg-gray-300 dark:bg-dark-bg-secondary'} items-center flex flex-row gap-1 px-4 h-fit cursor-pointer text-center py-2 rounded-r-lg`}>
                                <img src={nvgCashImg} alt="cash" height={28} width={28} className={`${isRealMoney ? '' : 'grayscale'}`} />
                                <p className={`${isRealMoney ? 'text-black dark:text-dark-text-primary' : 'text-gray-500 dark:text-dark-text-secondary'} font-bold`}>Novig Cash</p>
                            </div>
                        </div>

                        {isLoadingWager &&
                            <div className="absolute bg-gray-300/50 w-full h-full">
                                <Loader darkness={400} />
                            </div>
                        }


                        <div className="flex flex-row justify-around gap-2">
                            <div className="border border-gray-200 dark:border-dark-border-primary flex flex-col gap-1 rounded-md bg-white dark:bg-dark-bg-primary pt-1 px-2">
                                <p className="font-bold text-xs">STAKE</p>
                                <div className="flex flex-row items-center">
                                    <p className="font-bold">$</p>
                                    <input type="text" autoCorrect="off" className="py-1 outline-0 ring-0 focus:ring-0 w-full border-none !bg-transparent" value={stake} onChange={changeStake} />
                                </div>
                            </div>
                            <div className="bg-green-200 dark:bg-green-600 border border-green-400 dark:border-dark-border-primary flex flex-col gap-1 rounded-md pt-1 px-2">
                                <p className="font-bold text-xs">TO WIN</p>
                                <div className="flex flex-row items-center">
                                    <p className="font-bold">$</p>
                                    <input type="text" autoCorrect="off" className="py-1 outline-0 ring-0 focus:ring-0 w-full border-none !bg-transparent" value={toWin} onChange={() =>{}} />
                                </div>
                            </div>
                        </div>
                        <div onClick={() => geoCheck('novig')} className={`${isRealMoney ? 'cursor-pointer text-black hover:scale-[1.02] bg-gradient-to-r from-yellow-300 to-amber-500' : 'bg-gray-300 text-gray-500'} mb-4 items-center flex flex-row gap-4 rounded-lg text-xl font-bold shadow-md w-full text-center py-2 px-8 duration-100`}>
                            <p className="w-full">PLAY</p>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default BetSlip;
